<template>
  <div class="page-wrapper">
    <header class="navbar">
      <div class="logo-wrap">
        <img class="logo" src="@/assets/img/substance-logo-2023.png" alt="Substance" />
      </div>
      <div class="right">
        <a href="#programmation" v-scroll-to:-95 class="link"><p class="caps-bold">HORAIRE<span> DE LA JOURNÉE</span></p></a>
        <a href="#" class="cta" @click.prevent="openform"><p class="caps-bold">PARTICIPER</p></a>
      </div>
    </header>

    <section class="hero">
      <img src="@/assets/img/hero-2025.svg" alt="Substance Lab 2025">
      <!-- <a href="#" class="cta outline float-cta">Rendez-vous annuel substance</a> -->
    </section>

    <section class="img-bloc stretch">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title big">Le LAB SUBSTANCE<br>Sommet de création, de contenu et de média</h2>
        <h3 class="title small">Concrétisez vos connaissances sur l'industrie</h3>
        <p class="text">
          Votre rendez-vous annuel pour tout savoir sur la création, le contenu et le média est de retour. Profitez de formations, panels, discussions et activité de réseautage pour rester à l'affût de toutes les meilleures pratique et dernières tendance de l'industrie. Amenez votre savoir à un autre niveau pour garantir le succès de vos marques dans l'environnement actuel du marketing.
        </p>

        <div class="wrap-desc">
          <div class="col">
            <p class="text">
              EN BREF
            </p>
            <ul>
              <li class="text">+ de 10 experts</li>
              <li class="text">1 journée complète de formations</li>
              <li class="text">Cocktail de réseautage</li>
              <li class="text">Rafraîchissement et lunch fournis</li>
            </ul>
          </div>
          <div class="col">
            <p class="text">
              SUJETS COUVERTS
            </p>
            <ul>
              <li class="text">Marketing d'influence</li>
              <li class="text">Média</li>
              <li class="text">Marketing engagé</li>
              <li class="text">Et plus encore</li>
            </ul>
          </div>
        </div>

        <a href="#" class="cta" @click.prevent="openform"><p class="caps-bold">Confirmer ma présence</p></a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="infos-card">
          <div class="info">
            <p class="title">QUAND</p>
            <p class="info-text">23 janvier <br> 9H30</p>
          </div>
          <div class="info">
            <p class="title">Où</p>
            <p class="info-text">messorem <small>2233.rue Pitt.montréal</small></p>
          </div>
        </div>
      </div>
    </section>

    <section id="programmation" class="programmation">
      <div class="title-wrap">
        <h2 class="title big" data-inview="fadeInUp" data-delay="200">Le Lab Substance 2025</h2>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">9h30</p>
        </div>
        <div class="bloc no-click bleu">
          <div class="top">
            <h3 class="title small">Connexion formule réseautage</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <!-- <div class="arrow">
            <img src="@/assets/img/arrow-right.svg" alt="arrow">
          </div> -->
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">10h</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event1')">
          <div class="top">
            <h3 class="title small">Pelmorex : des décisions éclairées chaque jour</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/pelmorex.png" alt="Pelmorex" />
              <p class="text name"><span>/</span>  Pelmorex</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">10h30</p>
        </div>
        <div class="bloc-wrap">
          <div class="bloc" @click.prevent="openconference('event2')">
            <div class="top">
              <h3 class="title small">La communication caméléon: adapter votre approche selon le média et les plateformes</h3>
            </div>
            <div class="info">
              <p class="text salle">Le Labo</p>
              <div class="speaker">
                <img src="@/assets/img/elise.webp" alt="Elise El-Nawar" />
                <img src="@/assets/img/maxime.webp" alt="Maxime Soucy" class="ml" />
                <img src="@/assets/img/roxane.webp" alt="Roxane Noiseux" class="ml" />
                <p class="text name"><span>/</span>  Elise El-Nawar <br> Maxime Soucy <br> Roxane Noiseux</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
              </svg>
            </div>
          </div>
          <p class="text or">ou</p>
          <div class="bloc" @click.prevent="openconference('event3')">
            <div class="top">
              <h3 class="title small">Pignon sur rue:  optimiser son amplification numérique pour un impact local</h3>
            </div>
            <div class="info">
               <p class="text salle">L'atelier</p>
              <div class="speaker">
                <img src="@/assets/img/marc-andre.webp" alt="Marc-André Dufresne" />
                <img src="@/assets/img/marie-jade.webp" alt="Marie-Jade Filion" class="ml" />
                <p class="text name"><span>/</span> Marc-André Dufresne <br> Marie-Jade Filion</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">11h</p>
        </div>
        <div class="bloc-wrap">
          <div class="bloc" @click.prevent="openconference('event4')">
            <div class="top">
              <h3 class="title small">Capturer le poisson rouge: comment attirer l'attention dans un contexte de fatigue publicitaire</h3>
             
            </div>
            <div class="info">
              <p class="text salle">Le Labo</p>
              <div class="speaker">
                <img src="@/assets/img/philippe.webp" alt="Philippe Pétrin" />
                <img src="@/assets/img/mathilde.png" alt="Mathilde Burgat" class="ml" />
                <p class="text name"><span>/</span> Philippe Pétrin <br> Mathilde Burgat</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
          <p class="text or">ou</p>
          <div class="bloc" @click.prevent="openconference('event5')">
            <div class="top">
              <h3 class="title small">Changer sans profiter: éviter le rainbow washing</h3>
              
            </div>
            <div class="info">
              <p class="text salle">L'atelier</p>
              <div class="speaker">
                <img src="@/assets/img/genevieve.webp" alt="Geneviève Harel">
                <img src="@/assets/img/laurie.png" alt="Laurie Bigras " class="ml">
                <p class="text name"><span>/</span> Geneviève Harel <br> Laurie Bigras</p>
              </div>
            </div>
            <div class="arrow">
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

            </div>
          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">11h30</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event6')">
          <div class="top">
            <h3 class="title small">Naviguer l'Amazon: comment tirer le meilleur parti des données internes</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/amazon.png" alt="Amazon" />
              <p class="text name"><span>/</span>  Amazon Ads</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>
          </div>
        </div>
      </div>


      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">12h15</p>
        </div>
        <div class="bloc no-click bleu">
          <div class="top">
            <h3 class="title small">Lunch & Connexion Réseautage</h3>
            <p class="text salle">L'Atelier</p>
          </div>
          <!-- <div class="arrow">
            <img src="@/assets/img/arrow-right.svg" alt="arrow">
          </div> -->
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">13h</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event7')">
          <div class="top">
            <h3 class="title small">Look what you made me do: appliquer les succès du eras tour de taylor swift à votre marque</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/audrey.webp" alt="Audrey Dodier" />
              <img src="@/assets/img/roxane.webp" alt="Roxane Noiseux" class="ml">
              <img src="@/assets/img/maxime.webp" alt="Maxime Soucy" class="ml">
              <p class="text name"><span>/</span>  Audrey Dodier <br> Roxane Noiseux <br> Maxime Soucy</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">13h30</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event8')">
          <div class="top">
            <h3 class="title small">Panel: l'importance de la relation en marketing d'influence</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/cynthia-dulude.png" alt="Cynthia Dulude" />
              <img src="@/assets/img/kate-moya.png" alt="Kate Moya" class="ml" />
              <img src="@/assets/img/jeremie-latraille.png" alt="Jérémie Latreille" class="ml" />
              <img src="@/assets/img/pierre-olivier-beaudoin.png" alt="Pierre-Olivier Beaudoin" class="ml" />
              <img src="@/assets/img/anne-marie-dassylva.png" alt="Anne-Marie Dassylva" class="ml" />
              <p class="text name"><span>/</span> Cynthia Dulude<br>Kate Moya<br>Jérémie Latreille<br>Pierre-Olivier Beaudoin<br>Anne-Marie Dassylva</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">14h</p>
        </div>
        <div class="bloc" @click.prevent="openconference('event9')">
          <div class="top">
            <h3 class="title small">Purpose of purpose</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <img src="@/assets/img/ron.webp" alt="" />
              <p class="text name"><span>/</span> Ron Tite</p>
            </div>
          </div>
          <div class="arrow">
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="#2B2B2B"/>
            </svg>

          </div>
        </div>
      </div>

      <div class="row" data-inview="fadeInUp" data-delay="200">
        <div class="time">
          <p class="title">15h</p>
        </div>
        <div class="bloc no-click">
          <div class="top">
            <h3 class="title small">Connexion formule réseautage</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="info">
            <div class="speaker">
              <!-- <img src="@/assets/img/tiktok.png" alt="Tik Tok logo" /> -->
              <!-- <p class="text name"><span>/</span> par Tik Tok</p> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="img-bloc yellow">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">NE MANQUEZ PAS ÇA !</h2>
        <p class="text darker">
          Le 23 janvier prochain, on vous attend dès 9h30 à la microbrasserie Messorem.
        </p>
        <h3 class="title small">MESSOREM</h3>
        <p class="text darker">2233 Rue Pitt, Montréal, QC H4E 4H2</p>
        <a href="#" class="cta" @click.prevent="openform"><p class="caps-bold">Confirmer ma présence</p></a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <a
          href="https://www.google.com/maps?q=messorem+bracitorium&gs_lcrp=EgZjaHJvbWUqDQgBEC4YrwEYxwEYgAQyBggAEEUYOTINCAEQLhivARjHARiABDIHCAIQABiABDIHCAMQABiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDIHCAgQABiABNIBCDM2ODJqMGoxqAIAsAIA&um=1&ie=UTF-8"
          target="_blank"
          ><img src="@/assets/img/messorem.svg" alt="Google map"
        /></a>
      </div>
    </section>

    <section class="footer">
      <img class="logo" src="@/assets/img/substance-logo-2023.png" alt="Logo Substance">
    </section>

    <!--FORM-->
    <section class="panel-wrap" :class="{ active: form }">
      <div class="panel" v-if="!isSuccess">
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="closeform" class="close">
          <path d="M2 2L22 21.0482" stroke="#2B2B2B" stroke-width="3"/>
          <path d="M22 2L2 21.0482" stroke="#2B2B2B" stroke-width="3"/>
        </svg>

        <p class="title small">Confirmer ma présence</p>

        <form action="/?success=1" name="soumissions" method="post" enctype="multipart/form-data" @submit="sendForm">
          <input v-model="formData.nom" type="text" placeholder="Prénom et nom" required />
          <input v-model="formData.entreprise" type="text" placeholder="Entreprise" required />
          <input v-model="formData.courriel" type="mail" placeholder="Courriel" required>

          <div class="wrap">
            <p class="title hour">9h30</p>
            <label class="choice" @click.prevent.stop="addFormation('10h : Connexion formule réseautage')" >
              <div class="prevent"></div>
              Connexion formule réseautage
              <input type="checkbox" value="Connexion formule réseautage" :checked="formData.formations.includes('10h : Connexion formule réseautage')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">10h</p>
            <label class="choice" @click.prevent.stop="addFormation('10h : Pelmorex : des décisions éclairées chaque jour')" >
              <div class="prevent"></div>
              Pelmorex : des décisions éclairées chaque jour
              <input type="checkbox" value="Pelmorex : des décisions éclairées chaque jour" :checked="formData.formations.includes('10h : Pelmorex : des décisions éclairées chaque jour')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">10h30</p>

            <label class="choice half" @click.prevent.stop="addFormation('10h30 : La communication caméléon: adapter votre approche selon le média et les plateformes', '10h30')" >
              <div class="prevent"></div>
              La communication caméléon: adapter votre approche selon le média et les plateformes
              <input type="radio" value="La communication caméléon: adapter votre approche selon le média et les plateformes" :checked="formData.formations.includes('10h30 : La communication caméléon: adapter votre approche selon le média et les plateformes')" />
              <div class="radio-faker"></div>
            </label>
            <p class="text">ou</p>
            <label class="choice half" @click.prevent.stop="addFormation('10h30 : Pignon sur rue:  optimiser son amplification numérique pour un impact local', '10h30')" >
              <div class="prevent"></div>
              Pignon sur rue:  optimiser son amplification numérique pour un impact local
              <input type="radio" value="Pignon sur rue:  optimiser son amplification numérique pour un impact local" :checked="formData.formations.includes('10h30 : Pignon sur rue:  optimiser son amplification numérique pour un impact local')" />
              <div class="radio-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">11h</p>

            <label class="choice half" @click.prevent.stop="addFormation('11h : Capturer le poisson rouge: comment attirer l\'attention dans un contexte de fatigue publicitaire', '11h')" >
              <div class="prevent"></div>
              Capturer le poisson rouge: comment attirer l'attention dans un contexte de fatigue publicitaire
              <input type="radio" value="Capturer le poisson rouge: comment attirer l'attention dans un contexte de fatigue publicitaire" :checked="formData.formations.includes('11h : Capturer le poisson rouge: comment attirer l\'attention dans un contexte de fatigue publicitaire')" />
              <div class="radio-faker"></div>
            </label>
            <p class="text">ou</p>
            <label class="choice half" @click.prevent.stop="addFormation('11h :Changer sans profiter: éviter le rainbow washing', '11h')" >
              <div class="prevent"></div>
              Changer sans profiter: éviter le rainbow washing
              <input type="radio" value="Changer sans profiter: éviter le rainbow washing" :checked="formData.formations.includes('11h :Changer sans profiter: éviter le rainbow washing')" />
              <div class="radio-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">11h30</p>
            <label class="choice half" @click.prevent.stop="addFormation('11h30 : Naviguer l\'Amazon: comment tirer le meilleur parti des données internes', '11h30')" >
              <div class="prevent"></div>
              Naviguer l'Amazon: comment tirer le meilleur parti des données internes
              <input type="radio" value="Naviguer l'Amazon: comment tirer le meilleur parti des données internes" :checked="formData.formations.includes('11h30 : Naviguer l\'Amazon: comment tirer le meilleur parti des données internes')" />
              <div class="radio-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">12h15</p>
            <label class="choice" @click.prevent.stop="addFormation('12h15 : Lunch & Connexion Réseautage')" >
              <div class="prevent"></div>
              Lunch & Connexion Réseautage
              <input type="checkbox" value="Lunch & Connexion Réseautage" :checked="formData.formations.includes('12h15 : Lunch & Connexion Réseautage')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">13h</p>
            <label class="choice" @click.prevent.stop="addFormation('13h : Look what you made me do: appliquer les succès du eras tour de taylor swift à votre marque')" >
              <div class="prevent"></div>
              Look what you made me do: appliquer les succès du eras tour de taylor swift à votre marque
              <input type="checkbox" value="Look what you made me do: appliquer les succès du eras tour de taylor swift à votre marque" :checked="formData.formations.includes('13h : Look what you made me do: appliquer les succès du eras tour de taylor swift à votre marque')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">13h30</p>
            <label class="choice" @click.prevent.stop="addFormation('13h30 : Panel: l\'importance de la relation en marketing d\'influence')" >
              <div class="prevent"></div>
              Panel: l'importance de la relation en marketing d'influence
              <input type="checkbox" value="Panel: l'importance de la relation en marketing d'influence" :checked="formData.formations.includes('13h30 : Panel: l\'importance de la relation en marketing d\'influence')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <div class="wrap">
            <p class="title hour">14h</p>
            <label class="choice" @click.prevent.stop="addFormation('14h : Purpose of purpose')" >
              <div class="prevent"></div>
              Purpose of purpose
              <input type="checkbox" value="Purpose of purpose" :checked="formData.formations.includes('14h : Purpose of purpose')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>
          <div class="wrap">
            <p class="title hour">15h</p>
            <label class="choice" @click.prevent.stop="addFormation('15h : Connexion formule réseautage')" >
              <div class="prevent"></div>
              Connexion formule réseautage
              <input type="checkbox" value="Connexion formule réseautage" :checked="formData.formations.includes('15h : Connexion formule réseautage')" />
              <div class="checkbox-faker"></div>
            </label>
          </div>

          <input type="submit" value="Envoyer ma participation">
        </form>

      </div>
      <div class="panel success-screen" v-else>
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="closeform" class="close">
          <path d="M2 2L22 21.0482" stroke="#2B2B2B" stroke-width="3"/>
          <path d="M22 2L2 21.0482" stroke="#2B2B2B" stroke-width="3"/>
        </svg>
        
        <p class="title small">Merci de votre <br> inscription!</p>
        <p class="text">
        <br>
        Rendez-vous le 23 janvier prochain à la brasserie Messorem </p>
      </div>
      <div class="overlay" @click.prevent="closeform"></div>
    </section>

    <!--Conférence Panel-->
    <section class="panel-wrap" :class="{ active: conference }">
      <div class="panel">
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="closeconference" class="close">
          <path d="M2 2L22 21.0482" stroke="#2B2B2B" stroke-width="3"/>
          <path d="M22 2L2 21.0482" stroke="#2B2B2B" stroke-width="3"/>
        </svg>

        <div class="event" :class="{ show: show === 'event1' }">
          <div class="top">
            <p class="hour title">10h</p>
            <h3 class="title small">Pelmorex : des décisions éclairées chaque jour</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
              <p class="text">Inscivez à notre crash course sur les données pour mieux comprendre le parcours de vos clients. Avec des insights sur vos succursales et celles de vos concurrents, vous pourrez utiliser la puissance des données pour mieux planifier et atteindre vos résultats.</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/pelmorex.png" alt="">
            <p class="text name"><span>/</span> Pelmorex</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event2' }">
          <div class="top">
            <p class="hour title">10h30</p>
            <h3 class="title small">La communication caméléon: adapter votre approche selon le média et les plateformes</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <p class="text">Chaque plateforme et canal a ses codes sociaux spécifiques à respecter pour maximiser son succès. Alors pourquoi traitons-nous toute la création de la même façon? Voici comment vous adapter en fonction des médias traditionnels ou sociaux.</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/elise.webp" alt="Elise El-Nawar">
            <p class="text name"><span>/</span> Elise El-Nawar</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/maxime.webp" alt="Maxime Soucy">
            <p class="text name"><span>/</span> Maxime Soucy</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/roxane.webp" alt="Roxane Noiseux">
            <p class="text name"><span>/</span> Roxane Noiseux</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event3' }">
          <div class="top">
            <p class="hour title">10h30</p>
            <h3 class="title small">Pignon sur rue:  optimiser son amplification numérique pour un impact local</h3>
           <p class="text salle">L'Atelier</p>
          </div>
          <div class="description">
            <p class="text">Envie d'accroître vos revenus avec votre présence locale? Découvrez comment optimiser, automatiser et mesurer l'impact réel du SEO pour bien l'utiliser et réduire vos coûts SEM.</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/marc-andre.webp" alt="Marc-André Dufresne">
            <p class="text name"><span>/</span> Marc-André Dufresne</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/marie-jade.webp" alt="Marie-Jade Filion ">
            <p class="text name"><span>/</span> Marie-Jade Filion</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event4' }">
          <div class="top">
            <p class="hour title">11h</p>
            <h3 class="title small">Capturer le poisson rouge: comment attirer l'attention dans un contexte de fatigue publicitaire</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <p class="text">Dans un monde constamment sur-stimulé, maximiser l'attention de vos cibles est la clé du succès. Du contenu authentique au UGC, apprenez comment optimiser votre création, votre storytelling et vos stratégie pour y arriver.</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/philippe.webp" alt="Philippe Petrin">
            <p class="text name"><span>/</span> Philippe Petrin</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/mathilde.png" alt="Mathilde Burgat">
            <p class="text name"><span>/</span> Mathilde Burgat</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event5' }">
          <div class="top">
            <p class="hour title">11h</p>
            <h3 class="title small">Changer sans profiter: éviter le rainbow washing</h3>
            <p class="text salle">L'Atelier</p>
          </div>
          <div class="description">
            <p class="text">Qu'est-ce que le rainbow-washing et pourquoi l'éviter? Bienvenue à notre cours 101 pour maîtriser le marketing conscient et responsable en restant fidèle à votre identité et votre mission de marque.</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/genevieve.webp" alt="Geneviève Harel">
            <p class="text name"><span>/</span> Geneviève Harel</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/laurie.png" alt="Laurie Bigras">
            <p class="text name"><span>/</span> Laurie Bigras</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event6' }">
          <div class="top">
            <p class="hour title">11h30</p>
            <h3 class="title small">Naviguer l'Amazon: comment tirer le meilleur parti des données internes</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <p class="text">Informations à venir</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/amazon.png" alt="Amazon">
            <p class="text name"><span>/</span> Amazon Ads</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event7' }">
          <div class="top">
            <p class="hour title">13h</p>
            <h3 class="title small">Look what you made me do: appliquer les succès du eras tour de taylor swift à votre marque</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <p class="text">La culture pop est une mine d'or de leçons et de bonnes pratiques pour guider nos stratégies marketing. Alors, étudions celle qui a redéfini les tournées grand échelle: nulle autre que Taylor Swift.</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/audrey.webp" alt="">
            <p class="text name"><span>/</span> Audrey Dodier</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/roxane.webp" alt="">
            <p class="text name"><span>/</span> Roxane Noiseux</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/maxime.webp" alt="">
            <p class="text name"><span>/</span> Maxime Soucy</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event8' }">
          <div class="top">
            <p class="hour title">13h30</p>
            <h3 class="title small">Panel: l'importance de la relation en marketing d'influence</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <p class="text">Une bonne campagne d'influence, c'est bien plus que des outils de mesure. Assistez à un panel d'invités spéciaux pour discuter de l'importance de l'humain et des relations de confiance dans l'industrie.</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/cynthia-dulude.png" alt="Cynthia Dulude" />
            <p class="text name"><span>/</span> Cynthia Dulude</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/kate-moya.png" alt="Kate Moya" />
            <p class="text name"><span>/</span> Kate Moya</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/jeremie-latraille.png" alt="Jérémie Latreille" />
            <p class="text name"><span>/</span> Jérémie Latreille</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/pierre-olivier-beaudoin.png" alt="Pierre-Olivier Beaudoin" />
            <p class="text name"><span>/</span> Pierre-Olivier Beaudoin</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/anne-marie-dassylva.png" alt="Anne-Marie Dassylva" />
            <p class="text name"><span>/</span> Anne-Marie Dassylva</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event9' }">
          <div class="top">
            <p class="hour title">14h</p>
            <h3 class="title small">Purpose of purpose</h3>
            <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <p class="text">Informations à venir</p>
          </div>
          <div class="speaker">
            <img src="@/assets/img/ron.webp" alt="">
            <p class="text name"><span>/</span> Ron Tite</p>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>

        <div class="event" :class="{ show: show === 'event10' }">
          <div class="top">
            <p class="hour title">15h00</p>
            <h3 class="title small">Connexion formule réseautage</h3>
           <p class="text salle">Le Labo</p>
          </div>
          <div class="description">
            <ul>
              <li class="text">Autour d'une bière : présentation des techniques de brassage éprouvées et dégustation</li>
            </ul>
          </div>
          <a href="#" class="cta" @click.prevent="function() { openform(); closeconference(); }"><p class="caps-bold">Confirmer ma présence</p></a>
        </div>
      </div>
      <div class="overlay" @click.prevent="closeconference"></div>
    </section>

  </div>
</template>

<script>
import { mapState } from 'vuex'


import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import createContact from '@/misc/firebase-init'

gsap.registerPlugin(ScrollTrigger)

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Le LAB SUBSTANCE - Votre rendez-vous périodique',
        separator: ' ',
        complement: '',
      },
      meta: [
        {
          name: 'description',
          content: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.',
          id: 'desc'
        },
        { name: 'application-name', content: 'Le LAB SUBSTANCE - Votre rendez-vous périodique' },
        { name: 'twitter:title', content: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.'},
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Le LAB SUBSTANCE - Votre rendez-vous périodique' },
        { property: 'og:site_name', content: 'Le LAB SUBSTANCE - Votre rendez-vous périodique' },
        {
          property: 'og:description',
          content: 'Après le succès des formations pour notre 10e anniversaire de l\'année dernière, on vous invite au Lab Substance. À travers plusieurs ateliers avec nos spécialistes, apprenez les meilleures pratiques du marketing en 2024, les tendances en ébullition, en plus de nos prédictions sur l\'industrie en 2025.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://substance-10-ans.netlify.app/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://substance-10-ans.netlify.app/img/og.jpg'
        }
      ]
    }
  },

  mounted() {
    this.initGsapUtils()
  },

  watch: {
    $route: {
      handler($route) {
        if ($route && $route.query.success === '1') {
          this.openform()
          this.isSuccess = true
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      form: false,
      conference: false,
      show: null,
      isSuccess: false,
      formData: {
        nom: '',
        entreprise: '',
        courriel: '',
        formations: []
      },
      isSending: false
    }
  },

  methods: {

    initGsapUtils() {
      const mtl = document.querySelector('.anim-mtl')
      const date = document.querySelector('.anim-date')
      const hour = document.querySelector('.anim-hour')

      if (mtl && date && hour) {

        gsap.to(mtl, {
          scrollTrigger: {
            trigger: mtl,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 2
          },
          rotation: 180,
          ease: 'none'
        })

        gsap.to(date, {
          scrollTrigger: {
            trigger: date,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 2
          },
          x: '-50%',
          ease: 'none'
        })

        gsap.to(hour, {
          scrollTrigger: {
            trigger: hour,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 2
          },
          x: '50%',
          ease: 'none'
        })
      }
    },
    
    openconference(uid) {
      this.conference = true
      this.show = uid
    },
    closeconference() {
      this.conference = false
      this.show = null
    },
    openform() {
      this.form = true
    },
    closeform() {
      this.form = false
    },
    addFormation(formation, uniqueTo = false) {
      if (uniqueTo) {
        this.formData.formations = this.formData.formations.filter(item => !item.includes(uniqueTo))
        this.formData.formations.push(formation)
        return
      }

      if (this.formData.formations.includes(formation)) {
        this.formData.formations = this.formData.formations.filter(item => item !== formation)
      } else {
        this.formData.formations.push(formation)
      }
    },
    async sendForm(event) {
      event.preventDefault()

      if (this.isSending) {
        return
      }

      this.isSending = true
      const contact = this.formData

      try {
        await createContact(contact)
        this.formData = {
          nom: '',
          entreprise: '',
          courriel: '',
          formations: []
        }
        window.location.href = '/?success=1'
      } catch (e) {
        this.error = e
        this.isSending = false
      }
    }
  },

  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
